/**
 * Created by LifeSoft on 28/11/17.
 */
import { Injectable } from '@angular/core';
import { EduTerm } from '../edu-term';
import { EduSemester } from '../edu-semester';
import { AcademicYear } from '../academic-year';
import { MainResponse } from '../response/main-response';
@Injectable()
export class CurrentAcademicCalendar {
	public id: number;
	public academic_year_id: number;
	public term_id: number;
	public semester_id: number;
	public term: EduTerm;
	public semester: EduSemester;
	public academicYear: AcademicYear;
	public term_vacation_date?: string;
	public next_term_reopening_date?: string;
	public number_of_days?: string;
}
@Injectable()
export class CurrentAcademicCalendarBody {
	public academic_year_id: number;
	public term_id: number;
	public semester_id: number;
	public term_vacation_date?: string;
	public next_term_reopening_date?: string;
	public number_of_days?: string;
}
@Injectable()
export class CurrentAcademicCalendarResponse extends MainResponse {
	data: CurrentAcademicCalendarResponseData | null;
}
@Injectable()
export class CurrentAcademicCalendarResponseData {
	currentCalendar: CurrentAcademicCalendar;
}
