/**
 * Created by LifeSoft on 08/11/17.
 */
import {Injectable} from '@angular/core';
@Injectable()
export class PostalBody {
    public line_1: string|null;
    public line_2: string|null;
    public city: string|null;
    public region: string|null;
    public zip_code: string|null;
    public country_id: number;
}
