import { AbstractControl, FormGroup } from '@angular/forms';
import { ValidateFormService } from '../validate-form.service';

export abstract class IFormValidator {
    protected formValidator: ValidateFormService;

    getFormControl(form: FormGroup, field: string): AbstractControl {
        return form.get(field);
    }
    displayLabelError(form: FormGroup, field: string): string {
        return this.formValidator.complainAboutErrorForLabel(this.getFormControl(form, field));
    }
    displayControlError(form: FormGroup, field: string): string {
        return this.formValidator.complainAboutError(this.getFormControl(form, field));
    }
    isFieldValid(form: FormGroup, field: string): boolean {
        return this.formValidator.isInputValid(this.getFormControl(form, field));
    }
    onValidateForm(form: FormGroup): void {
        Object.keys(form.controls).forEach((field) => {
          const control = form.get(field);
          control.markAsTouched({onlySelf: true});
        });
    }
}
