/**
 * Created by LifeSoft on 31/10/17.
 */
import {Injectable} from '@angular/core';
@Injectable()
export class MainResponse {
    public status: boolean;
    public message: string|null;
}

@Injectable()
export class EduResponse extends MainResponse {
    public data?: any;
}
